<template>
  <div ref="wrapEventVideo" class="wrap-event-video">
    <div class="wrap-video">
      <div ref="containerVideo" class="container-video" @mousedown="onMouseDown" @mousemove="onMouseMove"
      @mouseup="onMouseUp">
        <div v-if="videoIndex == -1 || isUnsupported || isRetryError" class="no-stream-info">
          {{ videoWarning  }}
        </div>
        <div v-else-if="isLoading" class="loader" />
        <video ref="video"
          :muted="muted"
          @play="onPlay"
          @pause="onPause"
          @loadstart="onLoadStart"
          @loadeddata="onLoadedData"
          @timeupdate="onTimeUpdate"
          @ended="onVideoEnd">
        </video>
      </div>
      <div class="control-panel">
        <div class="left">
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('previous_video')/*前一段影片*/" placement="top">
            <div class="control-btn" :class="{ disabled: prevVideoIndex === -1 }" @click="playPrevVideo">
              <img src="@/assets/icons/prevVideo.svg" />
            </div>
          </el-tooltip>
          <div class="control-btn" @click="playOrPause">
            <img :src="playBtnIcon" />
          </div>
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('next_video')/*後一段影片*/" placement="top">
            <div class="control-btn" :class="{ disabled: liveMode || nextVideoIndex === -1 }" @click="playNextVideo">
              <img src="@/assets/icons/nextVideo.svg" />
            </div>
          </el-tooltip>
          <div
            class="control-btn"
            :class="{ muted: muted }"
            @click="switchMuted"
          >
            <img :src="muteIcon" />
          </div>
          <input
            type="range"
            min="0"
            max="1"
            class="volume"
            :style="volumeStyle"
            step="0.01"
            v-model="volume"
            @input="volumeChange"
          />
        </div>
        <div class="right">
          <div class="dropup">
            <img src="@/assets/icons/speedometer.svg" />
            <button
              class="dropbtn"
              @mouseover="rateUpMouseOver"
            >
              {{ playRateLabel }}
            </button>
            <img class="drop" src="@/assets/icons/Drop.svg" />
            <div class="dropup-content" v-show="rateUpActive">
              <a
                v-for="item in playRateList"
                :key="item.value"
                :class="{ selected: item.value == playRate }"
                @click="selectRate(item.label, item.value)"
                >{{ item.label }}</a
              >
            </div>
          </div>
          <div class="dropup">
            <img src="@/assets/icons/ZoomIn.svg" />
            <button
              class="dropbtn"
              @mouseover="handleZoomList"
            >
              {{ scaleLabel }}
            </button>
            <img class="drop" src="@/assets/icons/Drop.svg" />
            <div class="dropup-content" v-show="showZoomList">
              <a
                v-for="item in zoomList"
                :key="item.value"
                :class="{ selected: item.value == scale  }"
                @click="selectZoom(item.value)"
                >{{ item.label }}</a
              >
            </div>
          </div>
          <div class="line"></div>
          <div class="wrap-btns">
            <div class="control-btn show-download" @click="handleDownloadPanel">
              <img src="@/assets/icons/download.svg" />
            </div>
            <div class="control-btn" @click="extendToFullscreen">
              <img src="@/assets/icons/fullscreen.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <TimeAxis
        ref="timeAxis"
        :liveList="liveList"
        :videoList="videoList"
        :videoIndex="videoIndex"
        :liveMode="liveMode"
        :videoCurrentSeconds="videoCurrentSeconds"
        :reTimeAxis="reTimeAxis"
        :permissionV2video="permissionV2.video"
        :axisRangeList="axisRangeList"
        :axisRange.sync="timeAxisRange"
        :showLiveBtn="isShowLiveBtn"
        :showBackEventBtn="true"
        :showEventRect="true"
        :eventData="eventRectData"
        @stopAndPlayAtSpecificTim="stopAndPlayAtSpecificTim"
        @loadVideoWithPointerTim="loadVideoWithPointerTim"
        @setReTimeAxis="setReTimeAxis"
        @switchLiveMode="playLiveVideo"
        @videoPause="videoPause"
        @backEvent="backEvent"
      />
    </transition>

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import Hls from 'hls.js'
import mse from '@/api/mse.js'
import Fmp4 from '@/api/Fmp4.js'
import { formatTime, formatTimeNoTime } from '@/utils/lib.js'
import { apiGetVideo } from '@/api/index.js'

export default {
  name: 'EventVideo',
  props: ['event', 'type'],
  components: {
    TimeAxis: () => import('@/components/TopBar/TimeAxis.vue'),
  },
  data() {
    return {
      liveMode: false,
      player: null,
      hls: null,
      fmp4Player: null,
      videoSrc: null,
      isUnsupported: false,
      isLoading: false,
      pause: false,
      muted: false,
      volume: 0.5,
      preVolume: 0.5,
      zoomList: [
        { label: '3X', value: 3.0 },
        { label: '2.5X', value: 2.5 },
        { label: '2.25X', value: 2.25 },
        { label: '2X', value: 2.0 },
        { label: '1.75X', value: 1.75 },
        { label: '1.5X', value: 1.5 },
        { label: '1.25X', value: 1.25 },
        { label: '1X', value: 1.0 },
      ],
      fullScreenMode: false,
      playRateList: [
        { label: '16X', value: 16.0 },
        { label: '8X', value: 8.0 },
        { label: '4X', value: 4.0 },
        { label: '2X', value: 2.0 },
        { label: '1X', value: 1.0 },
        { label: '0.5X', value: 0.5 },
        { label: '-1X', value: -1.0 }
      ],
      playRate: 1.0,
      rateUpActive: false,
      duration: '',
      timeElapsed: '',
      videoCurrentSeconds: 20,
      videoDurationSeconds: 100,
      dayList: ['日', '一', '二', '三', '四', '五', '六'],
      /* scale parameters */
      scale: 1.0,
      showZoomList: false,
      panning: false,
      pointX: 0,
      pointY: 0,
      start: { x: 0, y: 0 },
      divWidth: 0,
      divHeight: 0,
      videoWidth: 0,
      videoHeight: 0,
      paddingX: 0,
      paddingY: 0,
      reTimeAxis: false, // 重劃時間軸
      axisRangeList: [
        { label: '2H', value: 1 },
        { label: '1H', value: 0.5 },
        { label: '10M', value: 0.08333333 },
        { label: '5M', value: 0.04166667 },
      ],
      timeAxisRange: 0.08333333,
      retryQueryUrlNum: 0, // 當播放hls, 若檔案被移除，重取 mp4 videoUrl次數
      retryQueryUrlMaxNum: 3,
      isRetryError: false,
    }
  },
  computed: {
    ...mapState(['permissionV2', 'liveList']),
    ...mapState('video', ['eventInfo', 'videoList', 'videoIndex', 'playSpecificTime']),
    detectDate() {
      return formatTimeNoTime(this.event.detectTime)
    },
    detectDay() {
      return this.dayList[new Date(this.event.detectTime).getDay()]
    },
    playBtnIcon() {
      return this.pause
        ? require('@/assets/icons/play.svg')
        : require('@/assets/icons/pause.svg')
    },
    muteIcon() {
      return this.muted
        ? require('@/assets/icons/volume-mute.svg')
        : require('@/assets/icons/sound.svg')
    },
    timebarStyle() {
      return {
        '--timebar': this.videoCurrentSeconds / this.videoDurationSeconds * 100 + '%'
      }
    },
    volumeStyle() {
      return {
        '--volume': this.volume * 100 + '%'
      }
    },
    playRateLabel() {
      return this.playRateList.find(item => item.value === this.playRate).label
    },
    scaleLabel() {
      return this.zoomList.find(item => item.value === this.scale).label
    },
    videoWarning() {
      if (this.isUnsupported)
        return this.$t('video_unsupported_mime_codec')
      else if (this.isRetryError)
        return this.$t('retry_query')
      else
        return this.$t('no_video')
    },
    isShowLiveBtn() {
      const userId = this.type === 'sos' ? this.event.userAccount : this.event.user.id
      const live = this.liveList.find(item => item.id === userId)
      return live && this.$route.path === '/dashboard' && this.permissionV2.liveView > 0
    },
    prevVideoIndex() {
      // 移動時間軸時，不要頻繁運算前後影片的index，移動結束後才觸發
      if (this.$refs.timeAxis?.manualMoveTim) return -1

      if (this.videoList.length === 0 || this.videoIndex === this.videoList.length - 1) return -1
      // 時間點沒有停在任何一個影片上，需要計算出前一部影片的index
      if (this.videoIndex === -1) {
        if (this.liveMode) return 0 // 直播模式下，前一部影片是最新的影片
        
        const minTime = new Date(this.videoList[this.videoList.length - 1].startTime)
        if (moment(this.$refs.timeAxis.pointerTim).isBefore(minTime)) {
          return -1
        }

        const maxTime = new Date(this.videoList[0].stopTime)
        if (moment(this.$refs.timeAxis.pointerTim).isAfter(maxTime)) {
          return 0
        }

        for (let i = 0; i < this.videoList.length - 1; i++) {
          if (moment(this.$refs.timeAxis.pointerTim).isBetween(this.videoList[i+1].stopTime, this.videoList[i].startTime)) {
            return i + 1
          }
        }
      }

      // 時間點有停在某一部影片上，直接取前一部影片的index
      return (this.videoIndex < this.videoList.length - 1) ? this.videoIndex + 1 : -1
    },
    nextVideoIndex() {
      // 移動時間軸時，不要頻繁運算前後影片的index，移動結束後才觸發
      if (this.$refs.timeAxis?.manualMoveTim) return -1

      if (this.videoList.length === 0 || this.videoIndex === 0) return -1
      // 時間點沒有停在任何一個影片上，需要計算出前一部影片的index
      if (this.videoIndex === -1) {
        if (this.liveMode) return 0 // 直播模式下，前一部影片是最新的影片
        
        const minTime = new Date(this.videoList[this.videoList.length - 1].startTime)
        if (moment(this.$refs.timeAxis.pointerTim).isBefore(minTime)) {
          return this.videoList.length - 1
        }

        const maxTime = new Date(this.videoList[0].stopTime)
        if (moment(this.$refs.timeAxis.pointerTim).isAfter(maxTime)) {
          return -1
        }

        for (let i = 0; i < this.videoList.length - 1; i++) {
          if (moment(this.$refs.timeAxis.pointerTim).isBetween(this.videoList[i+1].stopTime, this.videoList[i].startTime)) {
            return i
          }
        }
      }

      // 時間點有停在某一部影片上，直接取前一部影片的index
      return (this.videoIndex > 0) ? this.videoIndex - 1 : -1
    },
    eventRectData() {
      let startTime = null
      let stopTime = null
      let detectTime = null
      let changeType = null
      switch (this.type) {
        case 'chased':
          // startTime = new Date(this.event.chasingStartTime)
          startTime = new Date(this.event.detectTime)
          stopTime = new Date(this.event.chasingEndTime)
          break
        case 'sos':
          startTime = new Date(this.event.startTime)
          stopTime = new Date(this.event.endTime)
          break
        case 'video':
          startTime = new Date(this.event.startTime)
          stopTime = new Date(this.event.stopTime)
          break
        default: // lpr, or
          if (this.event.chasingStartTime) {
            changeType = 'chased'
            startTime = new Date(this.event.chasingStartTime)
            stopTime = new Date(this.event.chasingEndTime)
          } else {
            detectTime = new Date(this.event.detectTime)
            startTime = new Date(detectTime.getTime() - 20 * 1000)
            stopTime = new Date(detectTime.getTime() + 20 * 1000)
          }
          break
      }
      return {
        type: changeType ? changeType : this.type,
        startTime: startTime,
        stopTime: stopTime,
      }
    },
  },
  watch: {
    'event.id'() {
      // this.timeAxisRange = this.getTimeAxisRange() // 取得時間軸範圍
      this.playVideo()
    },
    playSpecificTime() {
      if(this.playSpecificTime) {
        this.stopAndPlayAtSpecificTim(this.playSpecificTime)
      }
    },
  },
  mounted() {
    this.hls = new Hls()
    this.backEvent()
  },
  beforeDestroy() {
    if (this.intervalRewind) clearInterval(this.intervalRewind)
    if (this.hls) {
      this.hls.destroy()
    }
    if (this.fmp4Player) this.fmp4Player.close()
    this.fmp4Player = null
    this.stopLiveVideo()
  },
  methods: {
    ...mapMutations('video', [
      'updateVideoTime',
      'updateVideoIndex',
      'updateIsQueryByTime',
      'updateIsShowVideoDownloadPanel',
      'updateEventVideoList',
    ]),
    ...mapActions('video', ['getVideoGpsList', 'setClosedVideoIndex']),
    getTimeAxisRange() {
      if (this.videoList.length === 0) return 0.04166667
      const start = new Date(this.videoList[this.videoList.length - 1].startTime).getTime()
      const end = new Date(this.videoList[0].stopTime).getTime()
      const diff = (end - start) / 60000 // 分鐘
      let range = 0.04166667
      if (diff > 5) range = 0.08333333
      else if (diff > 10) range = 0.5
      else if (diff > 60) range = 1

      return range
    },
    playOrPause() {
      if (this.pause) {
        if (this.videoIndex >= 0)
          this.$refs.video.play()
      } else {
        this.$refs.video.pause()
      }
    },
    handleDownloadPanel() {
      this.updateIsQueryByTime(true) // 由時間軸這邊開啟，可用時間區間查詢
      this.updateIsShowVideoDownloadPanel(true)
    },
    extendToFullscreen() {
      if (!this.fullScreenMode) {
        this.$refs.wrapEventVideo.webkitRequestFullScreen()
      } else {
        document.webkitExitFullscreen()
      }
      this.fullScreenMode = !this.fullScreenMode
    },
    switchMuted() {
      this.muted = !this.muted
      if (this.muted) {
        this.preVolume = this.volume
        this.volume = 0
      } else {
        this.volume = this.preVolume
      }
      this.$refs.video.volume = this.volume
    },
    volumeChange() {
      this.$refs.video.volume = this.volume
    },
    rateUpMouseOver() {
      this.rateUpActive = true
    },
    handleZoomList() {
      this.showZoomList = true
    },
    selectRate(label, value) {
      this.playRate = value
      this.rateUpActive = !this.rateUpActive
      // document.getElementsByClassName('dropup-content')[0].style.display = 'none'
      if (value < 0) {
        this.rewind(1.0)
      } else {
        if (this.intervalRewind) clearInterval(this.intervalRewind)
        this.$refs.video.playbackRate = value
        this.$refs.video.play()
      }
    },
    selectZoom(value) {
      this.showZoomList = false
      this.scale = value
      if (this.scale == 1.0) this.resetZoomParams()
      else {
        this.correctTranslatePoint()
        this.setTransform()
      }
    },
    // 倒回播放功能
    rewind(rewindSpeed) {
      if (this.intervalRewind) clearInterval(this.intervalRewind)
      let video = this.$refs.video
      let startSystemTime = new Date().getTime()
      let startVideoTime = video.currentTime
      this.intervalRewind = setInterval(() => {
        video.playbackRate = 1.0
        if (video.currentTime < 0.1) {
          clearInterval(this.intervalRewind)
          video.pause()
          this.playRate = 1.0
        } else {
          let elapsed = new Date().getTime() - startSystemTime
          // log.textContent='Rewind Elapsed: '+elapsed.toFixed(3)
          video.currentTime = Math.max(
            startVideoTime - (elapsed * rewindSpeed) / 1000.0,
            0
          )
        }
      }, 30)
    },
    onPlay() {
      this.pause = false
      this.isUnsupported = false
    },
    onPause() {
      this.pause = true
    },
    onLoadStart() {
      this.isLoading = true
    },
    onLoadedData() {
      this.isLoading = false
      this.isUnsupported = false
      const videoDuration = Math.round(this.$refs.video.duration)
      this.duration = this.formatTime(videoDuration)
      this.getVideoParams()
    },
    onTimeUpdate() {
      if (!this.$refs.video) return
      this.videoCurrentSeconds = this.$refs.video.currentTime
      this.timeElapsed = this.formatTime(this.videoCurrentSeconds)
    },
    formatTime(timeInSeconds) {
      if (timeInSeconds === Infinity) return { minutes: '00', seconds: '00' }
      const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8)
      return {
        minutes: result.substr(3, 2),
        seconds: result.substr(6, 2)
      }
    },
    playPrevVideo() {
      if (this.prevVideoIndex === -1) return
      this.updateVideoIndex(this.prevVideoIndex)
      this.playVideo()
    },
    playNextVideo() {
      if (this.liveMode) return
      if (this.nextVideoIndex === -1) return
      this.updateVideoIndex(this.nextVideoIndex)
      this.playVideo()
    },
    playHlsVideo(secDiff = null) {
      this.$nextTick(() => {
        let stream = this.videoList[this.videoIndex].videoUrl
        let video = this.$refs['video']
        this.hls.loadSource(stream)
        this.hls.attachMedia(video)
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (secDiff !== null) {
            video.currentTime = secDiff
          }
          video.play()
        })
        this.hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                if (data.error.message === 'no EXTM3U delimiter') {
                  this.stopHlsVideo() // 取消hls播放,釋放資源

                  console.log('*** HLS已轉檔，5秒後改播放mp4 ***')
                  this.isLoading = true
                  setTimeout(() => {
                    this.hls2mp4(secDiff)
                  }, 5000)
                }
                break
              default:
                if (data.error.name === 'NotSupportedError')
                  this.isUnsupported = true
                this.stopHlsVideo()
                break
            }
          }
        })
      })
    },
    stopHlsVideo() {
      if (this.hls) {
        this.hls.detachMedia()
      }
    },
    async hls2mp4(secDiff = null) {
      try {
        if (this.retryQueryUrlNum > this.retryQueryUrlMaxNum) {
          console.log('*** 重取videoUrl超過3次，放棄 ***')
          this.isRetryError = true
          return
        }
        const videoId = this.videoList[this.videoIndex].id
        const res = await apiGetVideo(videoId)
        if (res.data.type === 'HLS') {
          // 若還是HLS，則再等5秒後再處理
          setTimeout(() => {
            this.retryQueryUrlNum++
            console.log('*** 還是HLS，再等5秒重取videoUrl ***')
            this.hls2mp4(secDiff)
          }, 5000)
        } else {
          this.$nextTick(() => {
            if (this.fmp4Player) this.fmp4Player.close()
            if (secDiff !== null) {
              this.$refs.video.currentTime = secDiff
            }
            this.fmp4Player = new Fmp4(this.$refs.video, res.data.videoUrl, this.handleFmp4Error)
          })
        }
      } catch(error) {
        console.log('[hls2mp4 error]:', error)
      } finally {
        this.isLoading = false
      }
    },
    playVideo(secDiff = null) {
      this.stopHlsVideo() // 取消hls播放,釋放資源
      if (this.fmp4Player) this.fmp4Player.close()
      this.fmp4Player = null

      const videoData = this.videoList[this.videoIndex]
      if (videoData.type == 'HLS' && this.videoIndex === 0) {
        this.playHlsVideo(secDiff)
      } else if (videoData.type == 'MP4') {
        this.$nextTick(() => {
          this.fmp4Player = new Fmp4(this.$refs.video, videoData.videoUrl, this.handleFmp4Error)
          this.$refs.video.playbackRate = this.playRate
          if (secDiff !== null) {
            this.$refs.video.currentTime = secDiff
          }         
        })
      }
    },
    handleFmp4Error(codec) {
      console.error('Unsupported MIME type or codec: ', codec)
      this.isUnsupported = true
    },
    onVideoEnd() {
      // videoIndex 越小, 影片越新
      if (this.videoIndex > 0) {
        this.updateVideoIndex(this.videoIndex - 1)
        this.playVideo()

        // 若是播放歷史影片時, 將目前播放影片同步為事件影片，
        // 左上的下載影片面板會顯示當下播放的影片，下方的影片資訊也是顯示當下播放的影片資訊
        if (this.eventInfo.eventType === 'video')
          this.updateEventVideoList([ this.videoList[this.videoIndex] ])
      }
    },
    stopAndPlayAtSpecificTim(tim) {
      this.liveMode = false // 先停止直播
      if(this.specificTimeout) { clearTimeout(this.specificTimeout) }
      // this.stopLiveVideo()
      this.specificTimeout = setTimeout(() => {
        this.getSpecificTimVideo(tim)
        this.reTimeAxis = false
      }, 200)
    },
    loadVideoWithPointerTim(pointerTim) {
      // 播放歷史影片時, 根據影片時間同步更新marker & path
      // 當影片播放時，再進行更新，否則在影片切換時pointerTim會突然跳到該段video片尾時間（不確定原因）
      if (this.$refs.video.currentTime > 0) {
        this.updateVideoTime(pointerTim.toISOString())
      }
    },
    getSpecificTimVideo(tim) {
      // 取得時間 tim 的影片index
      this.setClosedVideoIndex(tim)
      if (this.videoIndex >= 0) {
        this.playSpecificVideo(tim)
      } else {
        this.findVideoAfterFetch(tim)
      }
    },
    async getNewHistoryVideoList(tim) {
      // chased = 0 (非追車事件) / chased = 2 (正在追車事件) / chased = 3 (已完成追車事件)
      if (!this.event || (this.type === 'lpr' && this.event.chased === 0))
        return

      const params = {}
      if (this.type === 'sos') {
        params.postId = [this.event.userAccount]
      } else {
        params.postId = [this.event.user.id]
      }

      const duration = 120 // 分鐘
      params.startTime = moment(tim).subtract(duration, 'minutes').toISOString()
      params.stopTime = moment(tim).add(duration, 'minutes').toISOString()
      params.startPlayTime = tim // 設定開始播放時間

      await this.getVideoGpsList(params)
      this.reTimeAxis = false
    },
    playSpecificVideo(tim) {
      // 播放特定時間的歷史影片
      // this.updateVideoIndex(index)

      // 若是播放歷史影片時, 將目前播放影片同步為事件影片，
      // 左上的下載影片面板會顯示當下播放的影片，下方的影片資訊也是顯示當下播放的影片資訊
      if (this.eventInfo.eventType === 'video')
        this.updateEventVideoList([ this.videoList[this.videoIndex] ])

      // 先清除videoPath之後重劃
      // this.$store.commit('clearVideoPath')

      if(this.videoIndex < 0) return
      let s = new Date(this.utcToTaipeiTime(this.videoList[this.videoIndex].startTime))
      let secDiff = moment(tim).diff(s) / 1000

      this.playVideo(secDiff)
      this.$refs.timeAxis.manualMoveTim = false
    },
    async findVideoAfterFetch(tim) {
      // 用箭頭或是拖很快時 可能會直接拖到沒載到歷史影片的地方
      if (this.videoList.length > 0) {
        // 若tim < 最早的影片時間或 tim > 最新影片結束時間，則再撈影片
        const minTime = new Date(this.utcToTaipeiTime(this.videoList[this.videoList.length - 1].startTime))
        const maxTime = new Date(this.utcToTaipeiTime(this.videoList[0].stopTime))
        if (moment(tim).isBefore(minTime) || moment(tim).isAfter(maxTime)) {
          await this.getNewHistoryVideoList(tim)
        }
      } else {
        await this.getNewHistoryVideoList(tim)
      }

      this.setClosedVideoIndex(tim)

      // 若拖動時間軸到最新的時間，videoIndex會等於-1，在這個時候切換到直播模式
      if (this.videoIndex == -1) {
        this.$refs.video.pause()
        // 拖拉時間接近當下時間 切換直播
        let tims = new Date(tim).getTime() / 1000
        let nows = new Date().getTime() / 1000
        // console.log('拖拉時間接近當下時間 切換直播', tim, new Date(), (nows - tims))
        if (nows - tims < 10) {
          this.switchLiveMode()
        } else {
          this.$store.commit('updateLiveMode', false)
        }
        this.$refs.timeAxis.manualMoveTim = false
      } else {
        this.playSpecificVideo(tim)
      }
    },
    videoPause() {
      this.$refs.video.pause()
    },
    playLiveVideo() {
      if (this.liveMode) return // 已經在播放直播了

      this.stopLiveVideo()
      this.liveMode = true
      const ele = this.$refs.video
      const mseUrl = this.liveList.find(item => item.id === this.event.user.id).mseUrl
      this.player = new mse(ele, mseUrl, this.unSupportedMseHandler)
      this.player.startup()
      ele.play()
      this.showRate = '1X'
      this.playRate = 1.0
      this.$refs.video.playbackRate = 1.0
    },
    stopLiveVideo() {
      this.liveMode = false
      if (this.player) {
        this.player.close()
        this.player = null
      }
    },
    unSupportedMseHandler(codec) {
      console.log('[unSupportedMseHandler] codec: ', codec)
      this.stopLiveVideo()
      this.liveMode = false
      this.isUnsupported = true
      this.isLoading = false
    },
    utcToTaipeiTime(tim) {
      // 轉換成UI畫面上的時間
      return formatTime(tim)
    },
    setReTimeAxis(bool) {
      this.reTimeAxis = bool
    },
    getVideoParams() {
      // 計算影像zoomImg維持原長寬比例，顯示於zoom中，x,y 方向的padding
      this.divWidth = this.$refs.containerVideo.offsetWidth
      this.divHeight = this.$refs.containerVideo.offsetHeight
      let videoNaturalWidth = this.$refs.video.videoWidth
      let videoNaturalHeight = this.$refs.video.videoHeight
      let fitScale = Math.min(this.divWidth / videoNaturalWidth, this.divHeight / videoNaturalHeight)
      this.videoWidth = videoNaturalWidth * fitScale
      this.videoHeight = videoNaturalHeight * fitScale
      this.paddingX = (this.divWidth - this.videoWidth) / 2
      this.paddingY = (this.divHeight - this.videoHeight) / 2
    },
    resetZoomParams() {
      this.pointX = 0
      this.pointY = 0
      this.start = { x: 0, y: 0 }
      this.scale = 1.0
      this.$refs.containerVideo.style.transform = `scale(${this.scale})`
    },
    correctTranslatePoint() {
      let paddingLeft = -this.paddingX * this.scale
      let paddingRight = -(this.videoWidth * this.scale - paddingLeft - this.divWidth)
      let paddingTop = -this.paddingY * this.scale
      let paddingBottom = -(this.videoHeight * this.scale - paddingTop - this.divHeight)

      if (this.pointX > paddingLeft) this.pointX = paddingLeft
      if (this.pointX < paddingRight) this.pointX = paddingRight
      if (this.pointY > paddingTop) this.pointY = paddingTop
      if (this.pointY < paddingBottom) this.pointY = paddingBottom
    },
    setTransform() {
      this.$refs.containerVideo.style.transform = `translate(${this.pointX}px, ${this.pointY}px) scale(${this.scale})`
    },
    onWheel(e) {
      e.preventDefault()
      let xs = (e.clientX - this.pointX) / this.scale
      let ys = (e.clientY - this.pointY) / this.scale
      let delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY)
      if (delta > 0) { // 放大
        this.scale *= 1.05
      } else { // 縮小，最小倍率為1.0
        let tmpScale = this.scale / 1.05
        this.scale = tmpScale < 1.0 ? 1.0 : tmpScale
      }

      this.pointX = e.clientX - xs * this.scale
      this.pointY = e.clientY - ys * this.scale

      if (delta <= 0) this.correctTranslatePoint()
      if (this.scale == 1.0) this.resetZoomParams()

      this.setTransform()
    },
    onMouseDown(e) {
      e.preventDefault()
      this.start = { x: e.clientX - this.pointX, y: e.clientY - this.pointY }
      this.panning = true
    },
    onMouseMove(e) {
      e.preventDefault()
      if (!this.panning || this.scale == 1.0) return
      this.pointX = e.clientX - this.start.x
      this.pointY = e.clientY - this.start.y
      this.correctTranslatePoint()
      this.setTransform()
    },
    onMouseUp() {
      this.panning = false
    },
    backEvent() {
      let startTime = null
      let detectTime = null
      switch (this.type) {
        case 'chased':
          startTime = new Date(this.event.chasingStartTime)
          break
        case 'sos':
          startTime = new Date(this.event.startTime)
          break
        case 'video':
          startTime = new Date(this.event.startTime)
          break
        default: // lpr, or
          detectTime = new Date(this.event.detectTime)
          startTime = new Date(detectTime.getTime() - 10 * 1000)
          break
      } 
      
      if (this.$refs.timeAxis) {
        this.$refs.timeAxis.manualMoveTim = true
      }
      this.stopAndPlayAtSpecificTim(startTime)
      this.loadVideoWithPointerTim(startTime)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.wrap-event-video {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.wrap-video {
  width: 100%;
  height: calc(100% - 80px);
  overflow: hidden;
  border: 1px solid #4A5C7888;
  position: relative;
  margin-bottom: 7px;
}

.container-video {
  width: 100%;
  height: 100%;
  transform-origin: 0px 0px;
  transform: scale(1) translate(0px, 0px);
  position: relative;

  .loader {
    @include circle-loader(66px);
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }
}

.no-stream-info {
  width: 100%;
  height: 100%;
  background-color: #010101;
  color: #666666;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-video video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.control-panel {
  position: absolute;
  width: 100%;
  height: px2rem(36);
  bottom: 0;
  left: 0;
  padding: 0 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(25, 25, 25, 0.4);
  opacity: 0;
}

.wrap-video:hover .control-panel {
  opacity: 1;
  transition: opacity 0.5s;
}

.left, .right {
  display: flex;
  align-items: center;
}

.right {
  column-gap: 8px;
}

.line {
  width: 1px;
  height: 24px;
  background-color: #ffffff;
  opacity: 0.5;
}

.control-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: px2rem(36);
  height: px2rem(36);
  border-radius: 8px;

  &:hover {
    background: #ffffff1a;
    cursor: pointer;
  }

  &:active {
    background: #ffffff80;
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
    &:hover {
      background: unset;
    }
  }

  img {
    width: px2rem(20);
    height: px2rem(20);
  }
}

.volume {
  -webkit-appearance: none;
  width: px2rem(80);
  height: 0.5rem;
  margin-left: 12px;
  position: relative;
  left: -10px;
  border-radius: 5px;
  background-image: linear-gradient(
    to right,
    #ffcd16,
    #FFC600 var(--volume),
    #999696 var(--volume),
    #999696 100%
  );
}

.volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s;
  background-color: rgba(255, 255, 255);
  position: relative;
}

.dropup {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 4px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    .dropup-content {
      display: block;
    }
    .dropbtn {
      // background-color: rgba(255, 255, 255, 0.2);
    }
    img.drop {
      transform: rotate(180deg);
      transition: all 250ms;
    }
  }

  .dropbtn {
    background-color: transparent;
    color: var(--dropbtn-color);
    min-width: 20px;
    height: px2rem(36);
    font-size: px2rem(16);
    padding: 0 3px;
    margin: 0 4px;
    border: none;

  }

  img:first-child {
    width: px2rem(20);
    height: px2rem(20);
  }

  img.drop {
    width: 16px;
    height: 16px;
  }

  .dropup-content {
    display: none;
    position: absolute;
    font-size: px2rem(16);
    background-color: rgba(25, 25, 25, 0.4);
    width: 100%;
    max-height: 120px;
    overflow-y: overlay; 
    left: 0px;
    bottom: px2rem(36);
    z-index: 1;

    a {
      font-size: px2rem(16);
      color: #ffffff;
      padding: 3px;
      text-decoration: none;
      text-align: center;
      display: block;
      user-select: none;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
      &.selected {
        color: #ffcd16;
      }
    }
  }
}

.wrap-btns {
  display: flex;
}

.time span {
  font-weight: 300;
  color: #ffffff;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to, .fade-leave-active /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media (max-width: 1280px) {
  .dropup {
    .dropup-content {
      max-height: 80px;
    }
  }
}
</style>